/* eslint-disable no-unused-vars */
import React, {useState} from "react";
import {Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {TextDecrypt} from "../content/TextDecrypt";

import './Works.css';

import Cashout from '../../assets/recentprojects/cashout-demo.png';
import Medhead from '../../assets/recentprojects/medhead-consortium.png';
import Okedoc from '../../assets/recentprojects/okedoc-sep.png';

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: '3em',
        marginBottom: "auto",
    },
    links: {
        '& a': {
            color: theme.palette.text.primary,
            transition: 'color 0.3s ease',
        },
    },
}));

export const Works = () => {
    const classes = useStyles();
    const [projects, setProjects] = useState([
        {
            id: 1,
            title: 'Cashout',
            description: `Conception et développement d'une application iOS/Android qui permet aux utilisateurs d'acheter ou de 
            vendre des articles et des services. Les utilisateurs pouvaient également chatter directement avec le créateur de la publication et voir à quel emplacement de la carte l'article ou le service était disponible.`,
            alter: 'Cashout',
            technology: 'React Native, JavaScript',
            image: `${Cashout}`,
            links: [
                {title: "GitHub", link: "https://github.com/mr-andrej/cashout-reactnative"}
            ]
        },
        {
            id: 2,
            title: 'Medhead Consortium',
            description: `Conception et développement d'une application web visant à combler le manque et la demande d'une plateforme de nouvelle génération centrée sur le patient, capable d'améliorer les soins de base offerts, tout en étant réactive et opérationnelle en temps réel. Pour ce faire, elle relie directement les patients, 
                          les hôpitaux et les services d'urgence, ce qui simplifie grandement la recherche du lit d'hôpital le plus proche.`,
            alter: 'Medhead Consortium',
            technology: 'Next.js, TypeScript, Java',
            image: `${Medhead}`,
            links: [
                {title: "GitHub", link: "https://github.com/mr-andrej/medheadconsortium"},
                {
                    title: "Documentation",
                    link: "https://github.com/mr-andrej/Faites-adherer-les-parties-prenantes-avec-un-POC"
                }
            ]
        },
        {
            id: 3,
            title: 'Okédoc',
            description: `Conception et développement d'une application sous forme de dispositif médical conçu en partenariat avec le réseau de patients alSacEP. 
                          Okédoc accompagnait les patients atteints de sclérose en plaques dans leur vie quotidienne, partout et tout le temps.`,
            alter: 'Okédoc',
            technology: '.NET, C#',
            image: `${Okedoc}`,
            links: [
                {title: "Site (service archivé)", link: "https://www.okedoc.fr/"},
            ]
        },
    ]);

    return (
        <section id="works">
            <Container component="main" className={classes.main} maxWidth="md">
                {projects.map((project) => (
                    <div className="project" key={project.id}>
                        <div className="__img_wrapper">
                            <img src={project.image} alt={project.alter}/>
                        </div>
                        <div className="__content_wrapper">
                            <h3 className="title">
                                <TextDecrypt text={project.title}/>
                            </h3>
                            <p className="description">
                                {project.description}
                            </p>
                            <p className="technology">
                                {project.technology}
                            </p>
                            <p className={`links ${classes.links}`}>
                                {project.links.map((source, index, array) => (
                                    <React.Fragment key={index}>
                                        <a href={source.link}>{source.title}</a>
                                        {index < array.length - 1 && " | "}
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>
                    </div>
                ))}
            </Container>
        </section>
    );
};
