import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";

import "./SideNavbar.css";

export const SideNavbar = () => {
    const [activeNav, setActiveNav] = useState('#');

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '-20% 0px -80% 0px',
            threshold: 0,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveNav(`#${entry.target.id || ''}`);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observe sections
        const sections = ['home', 'works', 'about'];
        sections.forEach((section) => {
            const element = document.getElementById(section);
            if (element) observer.observe(element);
        });

        // Special handling for home section
        const handleScroll = () => {
            if (window.scrollY < 100) { // Adjust this value as needed
                setActiveNav('#');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            sections.forEach((section) => {
                const element = document.getElementById(section);
                if (element) observer.unobserve(element);
            });
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav>
            <a href="#home"
               onClick={() => setActiveNav('#')}
               className={activeNav === '#' ? 'active' : ''}
            >
                <Typography>
                    Home
                </Typography>
            </a>
            <a href="#works"
               onClick={() => setActiveNav('#works')}
               className={activeNav === '#works' ? 'active' : ''}
            >
                <Typography>
                    Projects
                </Typography>
            </a>
            <a href="#about"
               onClick={() => setActiveNav('#about')}
               className={activeNav === '#about' ? 'active' : ''}
            >
                <Typography>
                    About
                </Typography>
            </a>
        </nav>
    );
};