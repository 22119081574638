import React, {useContext} from 'react';
import {LogoLink} from '../components/logo/LogoLink';
import {Content} from '../components/content/Content';
import {Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ThemeToggle} from '../components/theme/ThemeToggle';
import {Resume} from '../components/resume/Resume';
import {SocialIcons} from '../components/content/SocialIcons';
import {SpeedDials} from '../components/speedDial/SpeedDial';
import {SideNavbar} from '../components/nav/SideNavbar';
import {Works} from '../components/works/Works';
import {About} from '../components/about/About';
import StormyNight from "../components/background/StormyNight";
import SunnyDay from "../components/background/SunnyDay";
import {ThemeContext} from "../components/theme/ThemeProvider";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));

export const Home = () => {
    const classes = useStyles();
    const {theme, toggleTheme} = useContext(ThemeContext);


    return (
        <>
            <div className={classes.root} id="home">
                {theme === 'dark' ? <StormyNight/> : <SunnyDay/>}
                <LogoLink/>
                <Content/>
                <ThemeToggle/>
                <Hidden smDown>
                    <SocialIcons/>
                </Hidden>
                <Hidden mdUp>
                    <SpeedDials/>
                </Hidden>
                <Resume/>
            </div>
            <SideNavbar/>
            <Works/>
            <About/>
        </>
    );
};