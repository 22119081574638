import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {TextDecrypt} from "./TextDecrypt";
import Resume from "../../settings/resume.json";
import {FirstName, LastName} from "../../utils/getName";

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: "auto",
        marginBottom: "auto",
    },
    heading: {
        marginLeft: theme.spacing(50),
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(10),
        },
    },
    name: {
        color: theme.palette.text.primary,
    },
    jobs: {
        color: theme.palette.text.primary,
        "@media (max-width: 768px)": {
            fontSize: '3rem',
        },
    },
    description: {
        color: theme.palette.text.secondary,
        "@media (max-width: 768px)": {
            fontSize: '1rem',
        },
        maxWidth: "65vw",
    },
}));

export const Content = () => {
    const classes = useStyles();

    return (
        <section id="home" className={classes.main}>
            <Container component="div" maxWidth="fd">
                <div className={classes.heading}>
                    <Typography variant="h5" component="h2" className={classes.name}>
                        <TextDecrypt text={`${FirstName} ${LastName}`}/>
                    </Typography>
                    <Typography variant="h1" component="h1" className={classes.jobs}>
                        <TextDecrypt text={`${Resume.basics.job1}`}/>
                    </Typography>
                    <Typography variant="h6" component="h6" className={classes.description}>
                        <TextDecrypt text={`${Resume.basics.summary}`}/>
                    </Typography>
                </div>
            </Container>
        </section>
    );
};