import React, {useContext} from 'react';
import {IconButton, Tooltip, Zoom} from '@material-ui/core';
import {Brightness4, Brightness7} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {ThemeContext} from "./ThemeProvider";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1000,
    },
    icon: {
        fontSize: '2rem',
    },
}));

export const ThemeToggle = ({background}) => {
    const {theme, toggleTheme} = useContext(ThemeContext);
    const classes = useStyles();

    return (
        <Tooltip
            title={theme === "light" ? "Passer en mode sombre" : "Passer en mode clair"}
            placement="left"
            TransitionComponent={Zoom}
        >
            <IconButton
                color="inherit"
                onClick={toggleTheme}
                aria-label="Basculer le thème"
                className={classes.iconButton}
            >
                {theme === "light" ? (
                    <Brightness4 className={classes.icon}/>
                ) : (
                    <Brightness7 className={classes.icon}/>
                )}
            </IconButton>
        </Tooltip>
    );
};