import React, {lazy} from "react";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {HelmetMeta} from "./HelmetMeta";
import {ThemeProvider} from "../components/theme/ThemeProvider";
import {CssBaseline} from "@material-ui/core";

import {Home} from "../pages/Home";

const PageNotFound = lazy(() => import("../pages/PageNotFound"));

export const App = () => {
    return (
        <ThemeProvider>
            <CssBaseline/>
            <Router>
                <HelmetMeta/>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="*" component={PageNotFound}/>
                </Switch>
            </Router>
        </ThemeProvider>
    );
};
