/* eslint-disable no-unused-vars */
import React from "react";
import {Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {TextDecrypt} from "../content/TextDecrypt";
import {FirstName, LastName} from "../../utils/getName";

import './About.css';

import profile from '../../assets/profile.png';

const useStyles = makeStyles((theme) => ({
    main: {
        maxWidth: '100vw',
        marginTop: '3em',
        marginBottom: "5vw",
    },
}));

export const About = () => {
    const classes = useStyles();
    const greetings = "Hello hello!";

    return (
        <section id="about">
            <Container component="main" className={classes.main} maxWidth="md">
                <div className="about">
                    <div className="_img"
                         style={{
                             background: "url(" + profile + ")",
                             backgroundSize: 'contain',
                             backgroundPosition: 'center',
                             backgroundRepeat: 'no-repeat',
                         }}
                    >
                    </div>
                    <div className="_content_wrapper">
                        <Typography component='h2' variant="h5">
                            <TextDecrypt text={`${greetings}`}/>
                        </Typography>
                        <p className="aboutme">
                            Je suis {FirstName} {LastName}, un ingénieur logiciel motivé et passionné par le
                            développement de
                            solutions efficaces. J'ai contribué aux <strong>Data & Digital Services de la Société
                            Générale</strong>, en
                            automatisant les parcours utilisateurs et en travaillant avec Java, TypeScript et React.
                            Chez Actimage, j'ai mené de nouveaux développements pour IPOMEN, une solution intranet pour
                            le <strong>ministère de l'Éducation nationale</strong>. En tant qu'ingénieur DevOps chez
                            Okédoc, j'ai piloté le développement d'une nouvelle plateforme hébergeant différents
                            services.
                            Je suis titulaire d'un Master en
                            architecture logicielle et d'un Bachelor en développement Full-Stack. Mon expertise
                            technique et mes compétences en gestion de projet me poussent à relever de nouveaux défis
                            dans le domaine du développement logiciel.
                        </p>
                    </div>
                </div>
            </Container>
        </section>
    );
};
