import {createTheme, responsiveFontSizes} from '@material-ui/core';
import Settings from '../../settings/settings.json';

export const primary = `${Settings.colors.primary}`;
export const secondary = `${Settings.colors.secondary}`;
export const black = `${Settings.colors.black}`;
export const white = `${Settings.colors.white}`;

const baseTheme = {
    typography: {
        fontSize: 16,
        htmlFontSize: 16,
        h1: {
            fontWeight: 500,
        },
        h2: {
            fontWeight: 500,
        },
        h3: {
            fontWeight: 500,
        },
        h5: {
            fontWeight: 500,
            fontFamily: 'Roboto Mono, monospace',
        },
        body: {
            fontWeight: 500,
            fontFamily: 'Roboto Mono, monospace',
        },
    },
    overrides: {
        MuiIconButton: {
            root: {
                boxShadow:
                    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
                '&:hover': {
                    backgroundColor: primary,
                },
                transition: 'all 0.5s ease',
            },
        },
        MuiFab: {
            root: {
                width: '2.5rem',
                height: '2.5rem',
                fontSize: '1.25rem',
            },
        },
        MuiSpeedDialAction: {
            fab: {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: primary,
                },
                transition: 'all 0.5s ease',
                margin: '0px',
                marginBottom: '16px',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontFamily: 'Roboto Mono, monospace',
                backgroundColor: primary,
                fontSize: 12,
            },
        },
    },
};

export const LightTheme = responsiveFontSizes(
    createTheme({
        ...baseTheme,
        palette: {
            type: 'light',
            primary: {
                main: primary,
            },
            secondary: {
                main: secondary,
            },
            background: {
                default: white,
            },
            foreground: {
                default: black,
            },
            text: {
                primary: black,
                secondary: '#333333',
            },
        },
        overrides: {
            ...baseTheme.overrides,
            MuiCssBaseline: {
                '@global': {
                    body: {
                        color: black,
                        backgroundColor: white,
                    },
                },
            },
            MuiFab: {
                ...baseTheme.overrides.MuiFab,
                primary: {
                    color: black,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        color: black,
                        backgroundColor: primary,
                    },
                    transition: 'all 0.5s ease !important',
                },
            },
            MuiSpeedDialAction: {
                ...baseTheme.overrides.MuiSpeedDialAction,
                fab: {
                    ...baseTheme.overrides.MuiSpeedDialAction.fab,
                    color: black,
                    '&:hover': {
                        color: black,
                    },
                },
            },
            MuiTooltip: {
                ...baseTheme.overrides.MuiTooltip,
                tooltip: {
                    ...baseTheme.overrides.MuiTooltip.tooltip,
                    color: black,
                },
            },
        },
    })
);

export const DarkTheme = responsiveFontSizes(
    createTheme({
        ...baseTheme,
        palette: {
            type: 'dark',
            primary: {
                main: primary,
            },
            secondary: {
                main: secondary,
            },
            background: {
                default: black,
            },
            foreground: {
                default: white,
            },
            text: {
                primary: white,
                secondary: '#cccccc',
            },
        },
        overrides: {
            ...baseTheme.overrides,
            MuiCssBaseline: {
                '@global': {
                    body: {
                        color: white,
                        backgroundColor: black,
                    },
                },
            },
            MuiFab: {
                ...baseTheme.overrides.MuiFab,
                primary: {
                    color: white,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        color: white,
                        backgroundColor: primary,
                    },
                    transition: 'all 0.5s ease !important',
                },
            },
            MuiSpeedDialAction: {
                ...baseTheme.overrides.MuiSpeedDialAction,
                fab: {
                    ...baseTheme.overrides.MuiSpeedDialAction.fab,
                    color: white,
                    '&:hover': {
                        color: white,
                    },
                },
            },
            MuiTooltip: {
                ...baseTheme.overrides.MuiTooltip,
                tooltip: {
                    ...baseTheme.overrides.MuiTooltip.tooltip,
                    color: white,
                },
            },
        },
    })
);