import React, {useEffect, useRef} from 'react';
import * as THREE from 'three';
import {Transition} from "react-transition-group";
import {usePrefersReducedMotion} from "../../hooks/usePrefersReducedMotion";
import {useInViewport} from "../../hooks/useInViewport";
import {reflow} from "../../utils/transition";
import './StormyNight.css';

const StormyNight = (props) => {
    const canvasRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const cloudParticlesRef = useRef([]);
    const rainRef = useRef(null);
    const flashRef = useRef(null);
    const prefersReducedMotion = usePrefersReducedMotion();
    const isInViewport = useInViewport(canvasRef);

    useEffect(() => {
        const rainCount = 15000;

        // Scene setup
        sceneRef.current = new THREE.Scene();
        cameraRef.current = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
        cameraRef.current.position.z = 1;
        cameraRef.current.rotation.x = 1.16;
        cameraRef.current.rotation.y = -0.12;
        cameraRef.current.rotation.z = 0.27;

        const ambient = new THREE.AmbientLight(0x555555);
        sceneRef.current.add(ambient);

        const directionalLight = new THREE.DirectionalLight(0xffeedd);
        directionalLight.position.set(0, 0, 1);
        sceneRef.current.add(directionalLight);

        flashRef.current = new THREE.PointLight(0x062d89, 30, 500, 1.7);
        flashRef.current.position.set(200, 300, 100);
        sceneRef.current.add(flashRef.current);

        rendererRef.current = new THREE.WebGLRenderer({canvas: canvasRef.current});
        sceneRef.current.fog = new THREE.FogExp2(0x11111f, 0.002);
        rendererRef.current.setClearColor(sceneRef.current.fog.color);
        rendererRef.current.setSize(window.innerWidth, window.innerHeight);

        // Rain setup
        const rainGeo = new THREE.BufferGeometry();
        const rainPositions = new Float32Array(rainCount * 3);
        const rainSizes = new Float32Array(rainCount);

        for (let i = 0; i < rainCount; i++) {
            rainPositions[i * 3] = Math.random() * 400 - 200;
            rainPositions[i * 3 + 1] = Math.random() * 500 - 250;
            rainPositions[i * 3 + 2] = Math.random() * 400 - 200;
            rainSizes[i] = 30;
        }

        rainGeo.setAttribute('position', new THREE.BufferAttribute(rainPositions, 3));
        rainGeo.setAttribute('size', new THREE.BufferAttribute(rainSizes, 1));

        const rainMaterial = new THREE.PointsMaterial({
            color: 0xaaaaaa,
            size: 0.15,
            transparent: true
        });

        rainRef.current = new THREE.Points(rainGeo, rainMaterial);
        sceneRef.current.add(rainRef.current);

        // Cloud setup
        const loader = new THREE.TextureLoader();
        loader.load(
            "https://static.vecteezy.com/system/resources/previews/010/884/548/original/dense-fluffy-puffs-of-white-smoke-and-fog-on-transparent-background-abstract-smoke-clouds-movement-blurred-out-of-focus-smoking-blows-from-machine-dry-ice-fly-fluttering-in-air-effect-texture-png.png",
            (texture) => {
                const cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
                const cloudMaterial = new THREE.MeshLambertMaterial({
                    map: texture,
                    transparent: true
                });

                for (let p = 0; p < 25; p++) {
                    const cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
                    cloud.position.set(
                        Math.random() * 800 - 400,
                        500,
                        Math.random() * 500 - 450
                    );
                    cloud.rotation.x = 1.16;
                    cloud.rotation.y = -0.12;
                    cloud.rotation.z = Math.random() * 360;
                    cloud.material.opacity = 0.6;
                    cloudParticlesRef.current.push(cloud);
                    sceneRef.current.add(cloud);
                }

                animate();
            }
        );

        // Window resize handler
        const handleResize = () => {
            cameraRef.current.aspect = window.innerWidth / window.innerHeight;
            cameraRef.current.updateProjectionMatrix();
            rendererRef.current.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            // Clean up Three.js resources
            sceneRef.current.remove(rainRef.current);
            cloudParticlesRef.current.forEach(cloud => sceneRef.current.remove(cloud));
            rendererRef.current.dispose();
        };
    }, []);

    const animate = () => {
        cloudParticlesRef.current.forEach(p => {
            p.rotation.z -= 0.002;
        });

        rainRef.current.position.z -= 0.222;
        if (rainRef.current.position.z < -200) {
            rainRef.current.position.z = 0;
        }

        if (Math.random() > 0.93 || flashRef.current.power > 100) {
            if (flashRef.current.power < 100) {
                flashRef.current.position.set(
                    Math.random() * 400,
                    300 + Math.random() * 200,
                    100
                );
            }
            flashRef.current.power = 50 + Math.random() * 500;
        }

        rendererRef.current.render(sceneRef.current, cameraRef.current);
        requestAnimationFrame(animate);
    };

    return (
        <Transition appear in onEnter={reflow} timeout={3000}>
            {(status) => (
                <canvas
                    ref={canvasRef}
                    className={`stormy-night stormy-night--${status}`}
                    {...props}
                />
            )}
        </Transition>
    );
};

export default StormyNight;